import React from "react"
import { connect } from "react-redux"
import { setcontrasto, setopenmain } from "../../../state/actions"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { navContrasti } from "../navigazione"

const Contrasti = props => {
  const data = useStaticQuery(graphql`
    query MyContrasti {
      allFile(sort: {fields: name}, filter: { absolutePath: { regex: "/contrasti/img/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const { lingua, contra, newContrasto, setOpenMain } = props

  const setContrasti = tipo => {
    newContrasto(tipo)
    setOpenMain("")
  }

  return (
    <div className="section">
      <div
        className="row columns is-multiline"
      >
        {navContrasti.map(contrasto => (
          <div
            className="column is-half"
            key={contrasto._id}
          >
            <button className="buttonLink" onClick={() => setContrasti(contrasto._id)}>
              <div
                className={contra === contrasto._id ? "card is-active" : "card"}
              >
                <div className="card-image">
                  <Img
                    fluid={
                      data.allFile.edges[contrasto._id].node.childImageSharp
                        .fluid
                    }
                    alt={contrasto[`nome_${lingua.lang}`]}
                  />
                </div>
                <div
                  className={
                    contra === contrasto._id
                      ? "card-content has-background-grey-lighter"
                      : "card-content"
                  }
                >
                  <div className="media">
                    <div className="media-content">
                    <p
                        className="title is-7 is-uppercase">
                        {contrasto[`nome_${lingua.lang}`]}
                      </p>

                     {/* <p
                        className="subtitle is-7">Info contrasti</p>*/}
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    contra: state.shop.camicia.contrasto,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    newContrasto: val => dispatch(setcontrasto(val)),
    setOpenMain: val => dispatch(setopenmain(val)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contrasti)
