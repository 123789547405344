import React from "react"
import { connect } from "react-redux"
import { setcolletto, setopenmain } from "../../../state/actions"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { navColletti } from "../navigazione"

const Colletti = props => {
  const data = useStaticQuery(graphql`
    query MyColletti {
      allFile(
        sort: { fields: name }
        filter: { absolutePath: { regex: "/colletti/img/" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const { lingua, collo, newColletto, setOpenMain } = props

  const setColletto = tipo => {
    newColletto(tipo)
    setOpenMain("")
  }

  return (
    <div className="section">
      <div
        className="row columns is-multiline"
      >
        {navColletti.map(colletto => (
          <div
            className="column is-half"
            key={colletto._id}
          >
            <button className="buttonLink" onClick={() => setColletto(colletto._id)}>
              <div className="card">
                <div className="card-image">
                  <Img
                    fluid={
                      data.allFile.edges[colletto._id].node.childImageSharp
                        .fluid
                    }
                    alt={colletto[`nome_${lingua.lang}`]}
                  />
                </div>

                <div
                  className={
                    collo === colletto._id
                      ? "card-content has-background-grey-lighter"
                      : "card-content"
                  }
                >
                  <div className="media">
                    <div className="media-content">
                      <p className="title is-7 is-uppercase">
                        {colletto[`nome_${lingua.lang}`]}
                      </p>

                      <p className="subtitle is-7">{colletto[`info_${lingua.lang}`]}</p>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    collo: state.shop.camicia.colletto,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    newColletto: val => dispatch(setcolletto(val)),
    setOpenMain: val => dispatch(setopenmain(val)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Colletti)
