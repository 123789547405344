import React from "react";
import { connect } from "react-redux";
import {
  settipomisure,
  aggiornagenere,
  aggiornafit,
} from "../../state/actions";

import { Scrollbars } from "react-custom-scrollbars";

import MisuraSlider from "../misuraslider";
import MisuraDaTaglia from "../misuradataglia";
import MisuraInvia from "../misurainvia";

const Misure = (props) => {
  const { lingua, tipo, setTipoMisure, setGenere, genere, fit, aggFit } = props;

  function selectGenere(valore) {
    setGenere(valore);
  }

  return (
    <div className="columns is-gapless has-background-white">
      <div className="column ">
        <div className="container-features">
          <Scrollbars autoHide style={{ height: "calc(100vh - 3.25rem)" }}>
            <div className="section" style={{ background: "#fff" }}>
              <div className="buttons has-addons is-centered">
                <button
                  onClick={() => selectGenere(0)}
                  className={
                    genere === 0 ? "button is-primary is-selected" : "button"
                  }
                >
                  {lingua.navigazione.misure.uomo}
                </button>
                <button
                  onClick={() => selectGenere(1)}
                  className={
                    genere === 1 ? "button is-primary is-selected" : "button"
                  }
                >
                  {lingua.navigazione.misure.bambino}
                </button>
                <button
                  onClick={() => selectGenere(2)}
                  className={
                    genere === 2 ? "button is-primary is-selected" : "button"
                  }
                >
                  {lingua.navigazione.misure.donna}
                </button>
              </div>

              <nav className={tipo === 0 ? "panel is-primary" : "panel"}>
                <button
                  className="panel-heading"
                  onClick={() => setTipoMisure(0)}
                  style={
                    tipo === 0
                      ? { pointerEvents: "none", width: "100%" }
                      : {
                          cursor: "pointer",
                          textAlign: "left",
                          width: "100%",
                          border: "none",
                        }
                  }
                >
                  {lingua.navigazione.misure.metodoTaglia}
                </button>
                <div
                  className={tipo === 0 ? "" : "is-hidden"}
                  style={{ padding: "1.5rem" }}
                >
                  <MisuraDaTaglia lingua={lingua} />
                </div>
              </nav>
              {genere !== 2 && (
                <>
                  <nav className={tipo === 1 ? "panel is-primary" : "panel"}>
                    <button
                      className="panel-heading"
                      onClick={() => setTipoMisure(1)}
                      style={
                        tipo === 1
                          ? { pointerEvents: "none", width: "100%" }
                          : {
                              cursor: "pointer",
                              textAlign: "left",
                              width: "100%",
                              border: "none",
                            }
                      }
                    >
                      {lingua.navigazione.misure.metodoCorpo}
                    </button>
                    <div
                      className={tipo === 1 ? "" : "is-hidden"}
                      style={{ padding: "1.5rem" }}
                    >
                      <MisuraSlider lingua={lingua} tipo="corpo" />
                    </div>
                  </nav>

                  <nav className={tipo === 2 ? "panel is-primary" : "panel"}>
                    <button
                      className="panel-heading"
                      onClick={() => setTipoMisure(2)}
                      style={
                        tipo === 2
                          ? { pointerEvents: "none", width: "100%" }
                          : {
                              cursor: "pointer",
                              textAlign: "left",
                              width: "100%",
                              border: "none",
                            }
                      }
                    >
                      {lingua.navigazione.misure.metodoCamicia}
                    </button>
                    <div
                      className={tipo === 2 ? "" : "is-hidden"}
                      style={{ padding: "1.5rem" }}
                    >
                      <MisuraSlider lingua={lingua} tipo="camicia" />
                    </div>
                  </nav>
                </>
              )}
              <nav className={tipo === 3 ? "panel is-primary" : "panel"}>
                <button
                  className="panel-heading"
                  onClick={() => setTipoMisure(3)}
                  style={
                    tipo === 3
                      ? { pointerEvents: "none", width: "100%" }
                      : {
                          cursor: "pointer",
                          textAlign: "left",
                          width: "100%",
                          border: "none",
                        }
                  }
                >
                  {lingua.navigazione.misure.metodoInvia}
                </button>
                <div
                  className={tipo === 3 ? "" : "is-hidden"}
                  style={{ padding: "1.5rem" }}
                >
                  <MisuraInvia lingua={lingua} />
                </div>
              </nav>
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    tipo: state.shop.camicia.misure.tipo,
    genere: state.shop.camicia.misure.genere,
    fit: state.shop.camicia.misure.fit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTipoMisure: (val) => dispatch(settipomisure(val)),
    setGenere: (val) => dispatch(aggiornagenere(val)),
    aggFit: (val) => dispatch(aggiornafit(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Misure);
