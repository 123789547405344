import React from "react";
import { connect } from "react-redux";
import {
  setopenmain,
  setopenfiltri,
  setfiltripersonalizza,
} from "../state/actions";

import { graphql } from "gatsby";

import Tessuti from "../components/tessuti";
import Personalizzazione from "../components/personalizzazione";
import Iniziali from "../components/iniziali";
import Misure from "../components/misure";
import Viste from "../components/viste";
import Dettaglio from "../components/dettaglio";

import { lingua_it } from "../i18n/it-IT";
import { lingua_en } from "../i18n/en-GB";
import { lingua_es } from "../i18n/es-ES";
import { lingua_ca } from "../i18n/es-CA";

import Seo from "../components/seo";

const Camicia = (props) => {
  const {
    data,
    lang,
    cust,
    open,
    setOpenMain,
    filtri,
    setOpenFiltri,
    filtriPersonalizza,
    setFiltriPersonalizza,
    camicia,
  } = props;

  let lingua = {};

  switch (lang) {
    case "it-IT":
      lingua = lingua_it;
      break;
    case "es-ES":
      lingua = lingua_es;
      break;
    case "es-CA":
      lingua = lingua_ca;
      break;
    default:
      lingua = lingua_en;
  }

  const tessuto = data.airtable.data.codice;

  let personal = null;
  let titSezione = "";

  switch (cust) {
    case "tessuto":
      personal = <Tessuti lingua={lingua} corrente={tessuto} filtri={filtri} />;
      titSezione = lingua.navigazione.scegliTessuto;
      break;
    case "personalizzazione":
      personal = <Personalizzazione lingua={lingua} />;
      titSezione = lingua.navigazione.personalizzazione;
      break;
    case "iniziali":
      personal = (
        <Iniziali
          lingua={lingua}
          tessuto={
            data.airtable.data.immagine.localFiles[0].childImageSharp.fluid
          }
        />
      );
      titSezione = lingua.navigazione.iniziali.iniziali;
      break;
    case "misure":
      personal = <Misure lingua={lingua} />;
      titSezione = lingua.navigazione.misure.misure;
      break;
    default:
      personal = <Tessuti lingua={lingua} corrente={tessuto} filtri={filtri} />;
      titSezione = lingua.navigazione.scegliTessuto;
  }

  const post = data.airtable.data;

  return (
    <>
      <Seo
        title={lingua.barraAcquisto.camicia}
        keywords={[
          `3D Shirt`,
          `configuratore 3d per camicie`,
          `camicie su misura`,
        ]}
        description={
          lingua.barraAcquisto.camicia +
          " " +
          post.brand +
          " " +
          post.codice +
          " " +
          post[`descrizione_${lingua.lang}`]
        }
      />

      <div className="columns is-gapless has-background-grey-paky">
        <div
          className="column is-5-widescreen is-hidden-touch is-hidden-desktop-only"
          style={{ zIndex: 2 }}
        >
          {personal}
        </div>

        <div className={"quickview is-hidden-widescreen is-left " + open}>
          <header className="quickview-header">
            <p className="title">{titSezione}</p>
            <button className="delete" aria-label="Delete" onClick={() => setOpenMain("")}></button>
          </header>

          <div className="quickview-body">
            <div className="quickview-block">{personal}</div>
          </div>

          {cust === "tessuti" && (
            <footer className="quickview-footer is-hidden-tablet">
              <button
                className="button is-primary is-radiusless"
                onClick={() => setOpenFiltri(!filtri)}
              >
                {filtri ? lingua.tessuto : lingua.filtri}
              </button>
            </footer>
          )}
          {cust === "personalizzazione" && filtriPersonalizza === false && (
            <footer className="quickview-footer is-hidden-tablet">
              <button
                className="button is-primary is-radiusless"
                onClick={() => setFiltriPersonalizza(true)}
              >
                {lingua.filtri}
              </button>
            </footer>
          )}
        </div>

        <Viste
          tessuto={tessuto}
          lingua={lingua}
          data={post}
          camicia={camicia}
        />

        <Dettaglio data={post} lingua={lingua} camicia={camicia} />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
    cust: state.shop.customization,
    open: state.shop.openMain,
    filtri: state.shop.filtri,
    filtriPersonalizza: state.shop.filtriPersonalizza,
    camicia: state.shop.camicia,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setOpenMain: (val) => dispatch(setopenmain(val)),
    setOpenFiltri: (val) => dispatch(setopenfiltri(val)),
    setFiltriPersonalizza: (val) => dispatch(setfiltripersonalizza(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Camicia);

export const query = graphql`
  query($Path: String!) {
    airtable(data: { Path: { eq: $Path } }) {
      data {
        _id
        codice
        nome

        colore
        colore_dettaglio_it
        colore_dettaglio_es
        colore_dettaglio_fr
        colore_dettaglio_en
        materiale
        materiale_it
        materiale_es
        materiale_fr
        materiale_en
        brand
        immagine {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        prezzo_old
        prezzo
        disegno
        disegno_it
        disegno_es
        disegno_fr
        disegno_en
        descrizione_it
        descrizione_es
        descrizione_fr
        descrizione_en
        Path
      }
    }
  }
`;
