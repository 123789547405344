import React from "react";

import { Text } from "@react-three/drei/Text";

const Monogram = (props) => {
  const { iniziali, polsino } = props;

  let fontFam =
    "https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff";

  switch (iniziali.stile) {
    case "block":
      fontFam =
        "https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff";

      break;
    case "script":
      fontFam =
        "https://fonts.gstatic.com/s/alexbrush/v8/SZc83FzrJKuqFbwMKk6EhUXz6w.woff";

      break;
    case "serif":
      fontFam =
        "https://fonts.gstatic.com/s/philosopher/v9/vEFV2_5QCwIS4_Dhez5jcWBuT0s.woff";

      break;
    default:
      fontFam =
        "https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff";
  }

  let fontCol = "#f5f5f5";
  switch (iniziali.colore) {
    case 0:
      fontCol = "#f5f5f5";
      break;
    case 1:
      fontCol = "#0A0AAC";
      break;
    case 2:
      fontCol = "#082C4F";
      break;
    case 3:
      fontCol = "#BE0C0C";
      break;
    case 4:
      fontCol = "#121212";
      break;
    case 5:
      fontCol = "#1F1F1F";
      break;
      case 6:
        fontCol = "#7A2D0D";
        break;
        case 7:
      fontCol = "#074D07";
      break;
      case 8:
      fontCol = "#8D294E";
      break;
    default:
      fontCol = "#f5f5f5";
  }

  let fontPos = [0.06, 0.186, 0.087];
  switch (iniziali.posizione) {
    case 0:
      fontPos = [0.06, 0.186, 0.087];
      break;
    case 1:
      fontPos = [0.06, -0.016, 0.0985];
      break;
    case 2:
      fontPos = polsino === 3 ? [0.26, -0.319, 0.0545]:[0.26, -0.319, 0.0536];
      break;
    default:
      fontPos = [0.06, 0.186, 0.087];
  }

  return (
    <Text
      color={fontCol}
      scale={[0.07, 0.07, 0.6]}
      position={fontPos}
      font={fontFam}
    >
      {polsino === 4 && iniziali.posizione === 2 ? null : iniziali.testo}
    </Text>
  );
};

export default Monogram;
