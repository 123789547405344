import React from "react"
import { connect } from "react-redux"
import { settaschino, setopenmain } from "../../../state/actions"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { navTaschini } from "../navigazione"

const Taschini = props => {
  const data = useStaticQuery(graphql`
    query MyTaschini {
      allFile(sort: {fields: name}, filter: { absolutePath: { regex: "/taschini/img/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const { lingua, tasca, newTaschino, setOpenMain } = props

  const setTaschini = tipo => {
    newTaschino(tipo)
    setOpenMain("")
  }

  return (
    <div className="section">
      <div
        className="row columns is-multiline"
        
      >
        {navTaschini.map(taschino => (
          <div
            className="column is-half"
            key={taschino._id}
            
          >
            <button className="buttonLink" onClick={() => setTaschini(taschino._id)}>
              <div className={tasca === taschino._id ? "card is-active" : "card"}>
                <div className="card-image">
                <Img
                    fluid={
                      data.allFile.edges[taschino._id].node.childImageSharp
                        .fluid
                    }
                    alt={taschino[`nome_${lingua.lang}`]}
                  />
                </div>
                <div
                  className={
                    tasca === taschino._id
                      ? "card-content has-background-grey-lighter"
                      : "card-content"
                  }
                >
                  <div className="media">
                    <div className="media-content">
                    <p
                        className="title is-7 is-uppercase">
                        {taschino[`nome_${lingua.lang}`]}
                      </p>
                    {/*
                      <p
                        className="subtitle is-7">Info taschini</p>*/}
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    tasca: state.shop.camicia.taschino,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    newTaschino: val => dispatch(settaschino(val)),
    setOpenMain: val => dispatch(setopenmain(val)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Taschini)
