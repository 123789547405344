import React from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";
import {
  aggiornamisure,
  aggiornafit,
  aggiornafitdonna,
} from "../../state/actions";

const MisuraDaTaglia = (props) => {
  const {
    lingua,
    fit,
    fitDonna,
    aggMisure,
    aggFit,
    aggFitDonna,
    taglia,
    genere,
  } = props;

  const selectFit = (event) => {
    aggFit(event.target.value);
  };
  const selectFitDonna = (event) => {
    aggFitDonna(event.target.value);
  };

  const selectChange = (event) => {
    let mis = {};
    switch (event.target.value) {
      case "37":
        mis = {
          taglia: "37",
        };
        break;
      case "38":
        mis = {
          taglia: "38",
        };
        break;
      case "39":
        mis = {
          taglia: "39",
        };
        break;
      case "40":
        mis = {
          taglia: "40",
        };
        break;
      case "41":
        mis = {
          taglia: "41",
        };
        break;
      case "42":
        mis = {
          taglia: "42",
        };
        break;
      case "43":
        mis = {
          taglia: "43",
        };
        break;

      case "44":
        mis = {
          taglia: "44",
        };
        break;
      case "45":
        mis = {
          taglia: "45",
        };
        break;
      case "46":
        mis = {
          taglia: "46",
        };
        break;
      case "47":
        mis = {
          taglia: "47",
        };
        break;
      case "48":
        mis = {
          taglia: "48",
        };
        break;

      default:
        mis = {
          taglia: "41",
        };
    }
    aggMisure(mis);
  };

  const selectChangeBambino = (event) => {
    let mis = {};
    switch (event.target.value) {
      case "9":
        mis = {
          girovita: 51,
          torace: 53,
          braccia: 30,
          polso: 0,
          spalle: 22,
          collo: 20,
          lunghezza: 34,
          taglia: "9",
        };
        break;
      case "10":
        mis = {
          girovita: 53,
          torace: 56,
          braccia: 32,
          polso: 0,
          spalle: 23,
          collo: 21,
          lunghezza: 36,
          taglia: "10",
        };
        break;
      case "11":
        mis = {
          girovita: 55.5,
          torace: 59,
          braccia: 33,
          polso: 0,
          spalle: 24.5,
          collo: 22.5,
          lunghezza: 37,
          taglia: "11",
        };
        break;
      case "12":
        mis = {
          girovita: 57.5,
          torace: 62,
          braccia: 35,
          polso: 0,
          spalle: 26,
          collo: 24,
          lunghezza: 39,
          taglia: "12",
        };
        break;
      case "13":
        mis = {
          girovita: 59.5,
          torace: 65,
          braccia: 38,
          polso: 0,
          spalle: 27,
          collo: 25,
          lunghezza: 42,
          taglia: "13",
        };
        break;
      case "14":
        mis = {
          girovita: 61.5,
          torace: 68,
          braccia: 41,
          polso: 0,
          spalle: 28,
          collo: 26,
          lunghezza: 45,
          taglia: "14",
        };
        break;
      case "15":
        mis = {
          girovita: 64,
          torace: 71,
          braccia: 43,
          polso: 0,
          spalle: 29.5,
          collo: 27.5,
          lunghezza: 47,
          taglia: "15",
        };
        break;
      case "16":
        mis = {
          girovita: 67,
          torace: 74,
          braccia: 45,
          polso: 0,
          spalle: 30.5,
          collo: 28.5,
          lunghezza: 49,
          taglia: "16",
        };
        break;
      case "17":
        mis = {
          girovita: 69,
          torace: 77,
          braccia: 47,
          polso: 23.5,
          spalle: 31,
          collo: 30,
          lunghezza: 52,
          taglia: "17",
        };
        break;
      case "18":
        mis = {
          girovita: 71,
          torace: 80,
          braccia: 48.5,
          polso: 0,
          spalle: 33,
          collo: 31,
          lunghezza: 53,
          taglia: "18",
        };
        break;
      case "19":
        mis = {
          girovita: 74,
          torace: 83,
          braccia: 50,
          polso: 0,
          spalle: 34,
          collo: 32,
          lunghezza: 54,
          taglia: "19",
        };
        break;
      case "20":
        mis = {
          girovita: 77,
          torace: 86,
          braccia: 51.5,
          polso: 0,
          spalle: 35.5,
          collo: 34.5,
          lunghezza: 56,
          taglia: "20",
        };
        break;
      default:
        mis = {
          girovita: 61.5,
          torace: 68,
          braccia: 41,
          polso: 0,
          spalle: 28,
          collo: 26,
          lunghezza: 45,
          taglia: "21",
        };
    }
    aggMisure(mis);
  };

  return (
    <div>
      {genere === 0 && (
        <div className="field" style={{ marginBottom: "1.5rem" }}>
          <label className="label">
            {lingua.navigazione.misure.selezionaTaglia}
          </label>
          <div className="control">
            <div className="select">
              <select
                value={taglia}
                onBlur={selectChange}
                onChange={selectChange}
              >
                <option value="37">
                  {lingua.navigazione.misure.Collo} 37/XS
                </option>
                <option value="38">
                  {lingua.navigazione.misure.Collo} 38/S
                </option>
                <option value="39">
                  {lingua.navigazione.misure.Collo} 39/M
                </option>
                <option value="40">
                  {lingua.navigazione.misure.Collo} 40/M
                </option>
                <option value="41">
                  {lingua.navigazione.misure.Collo} 41/L
                </option>
                <option value="42">
                  {lingua.navigazione.misure.Collo} 42/L
                </option>
                <option value="43">
                  {lingua.navigazione.misure.Collo} 43/XL
                </option>
                <option value="44">
                  {lingua.navigazione.misure.Collo} 44/XL
                </option>
                <option value="45">
                  {lingua.navigazione.misure.Collo} 45/XXL
                </option>
                <option value="46">
                  {lingua.navigazione.misure.Collo} 46/XXL
                </option>
                <option value="47">
                  {lingua.navigazione.misure.Collo} 47/XXXL
                </option>
                <option value="48">
                  {lingua.navigazione.misure.Collo} 48/XXXL
                </option>
              </select>
            </div>
          </div>
        </div>
      )}
      {genere === 1 && (
        <div className="field" style={{ marginBottom: "1.5rem" }}>
          <label className="label">
            {lingua.navigazione.misure.selezionaTagliaBambino}
          </label>
          <div className="control">
            <div className="select">
              <select value={taglia} onChange={selectChangeBambino}>
                <option value="9">1 {lingua.navigazione.misure.anno}</option>
                <option value="10">2 {lingua.navigazione.misure.anni}</option>
                <option value="11">3 {lingua.navigazione.misure.anni}</option>
                <option value="12">4 {lingua.navigazione.misure.anni}</option>
                <option value="13">5 {lingua.navigazione.misure.anni}</option>
                <option value="14">6 {lingua.navigazione.misure.anni}</option>
                <option value="15">7 {lingua.navigazione.misure.anni}</option>
                <option value="16">8 {lingua.navigazione.misure.anni}</option>
                <option value="17">9 {lingua.navigazione.misure.anni}</option>
                <option value="18">10 {lingua.navigazione.misure.anni}</option>
                <option value="19">11 {lingua.navigazione.misure.anni}</option>
                <option value="20">12 {lingua.navigazione.misure.anni}</option>
              </select>
            </div>
          </div>
        </div>
      )}
      {genere === 2 && (
        <div className="field" style={{ marginBottom: "1.5rem" }}>
          <label className="label">
            {lingua.navigazione.misure.selezionaTaglia}
          </label>
          <div className="control">
            <div className="select">
              <select
                value={taglia}
                onBlur={selectChange}
                onChange={selectChange}
              >
                <option value="s">S</option>
                <option value="m">M</option>
                <option value="l">L</option>
                <option value="xl">XL</option>
              </select>
            </div>
          </div>
        </div>
      )}

      <div className="field">
        <Link to="/sizes-tables" className="button is-primary">
          {lingua.navigazione.misure.tabellaTaglie}
        </Link>
      </div>

      {genere === 0 && (
        <div className="field">
          <label className="label">
            {lingua.navigazione.misure.selezionaVestibilita}
          </label>
          <div className="control">
            <div className="select">
              <select value={fit} onBlur={selectFit} onChange={selectFit}>
                <option value="Slim">Slim</option>
                <option value="Regular">Regular</option>
                <option value="Polo">{lingua.navigazione.misure.polo}</option>
              </select>
            </div>
          </div>
        </div>
      )}
      {genere === 1 && (
        <div className="field">
          <label className="label">
            {lingua.navigazione.misure.selezionaVestibilita}
          </label>
          <div className="control">
            <div className="select">
              <select value={fit} onBlur={selectFit} onChange={selectFit}>
                <option value="Slim">Slim</option>
                <option value="Regular">Regular</option>
                <option value="Polo">{lingua.navigazione.misure.polo}</option>
              </select>
            </div>
          </div>
        </div>
      )}
      {genere === 2 && (
        <div className="field">
          <label className="label">
            {lingua.navigazione.misure.selezionaVestibilita}
          </label>
          <div className="control">
            <div className="select">
              <select
                value={fitDonna}
                onBlur={selectFitDonna}
                onChange={selectFitDonna}
              >
                <option value="Slim">Slim</option>
                <option value="Regular">Regular</option>
                <option value="Polo">{lingua.navigazione.misure.polo}</option>
                <option value={lingua.navigazione.misure.vestito}>
                  {lingua.navigazione.misure.vestito}
                </option>
                <option value="Boy-friend">Boy-friend</option>
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    genere: state.shop.camicia.misure.genere,
    taglia: state.shop.camicia.misure.taglia,
    fit: state.shop.camicia.misure.fit,
    fitDonna: state.shop.camicia.misure.fitDonna,
    tipo: state.shop.camicia.misure.tipo,
    sBraccia: state.shop.camicia.misure.braccia,
    sCollo: state.shop.camicia.misure.collo,
    sGirovita: state.shop.camicia.misure.girovita,
    sLunghezza: state.shop.camicia.misure.lunghezza,
    sPolso: state.shop.camicia.misure.polso,
    sSpalle: state.shop.camicia.misure.spalle,
    sTorace: state.shop.camicia.misure.torace,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    aggMisure: (val) => dispatch(aggiornamisure(val)),
    aggFit: (val) => dispatch(aggiornafit(val)),
    aggFitDonna: (val) => dispatch(aggiornafitdonna(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MisuraDaTaglia);
