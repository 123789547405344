export const navColletti = [
  {
    _id: 0,
    nome_it: "Italiano",
    nome_en: "Italian",
    nome_fr: "Italien",
    nome_es: "Italiano",
    nome_ca: "Italià",
    info_it: "Versatile con le punte chiuse e allungate verso il basso",
    info_en: "Versatile with elongated, downward and closer necktips",
    info_fr: "Versatil amb puntes tancades i allargades cap avall",
    info_es: "Versatil con puntas cerradas y alargadas hacia abajo",
    info_ca: "Versatil amb puntes tancades i allargades cap avall",
  },
  {
    _id: 1,
    nome_it: "Italiano classico",
    nome_en: "Classic italian",
    nome_fr: "Italien classique",
    nome_es: "Italiano clásico",
    nome_ca: "Italià classic",
    info_it: "Punte più aperte e corte ",
    info_en: "Neck tips more open and short",
    info_fr: "Puntes més obertes i curtes",
    info_es: "Puntas mas abiertas y cortas",
    info_ca: "Puntes més obertes i curtes",
  },
  {
    _id: 2,
    nome_it: "Francese",
    nome_en: "French",
    nome_fr: "Cou francais",
    nome_es: "Francés clásico",
    nome_ca: "Francès",
    info_it: "Moderno con punte più aperte e corte",
    info_en: "Modern with open and shorter neck tips",
    info_fr: "Modern amb puntes més obertes i curtes",
    info_es: "Moderno con puntas mas abiertas y cortas",
    info_ca: "Modern amb puntes més obertes i curtes",
  },
  {
    _id: 3,
    nome_it: "Francese semiaperto",
    nome_en: "Semi french",
    nome_fr: "Cou semi francais",
    nome_es: "Semi-abierto francés",
    nome_ca: "Francès semi obert",
    info_it: "Moderno con punte più vicine",
    info_en: "Modern with closer neck tips",
    info_fr: "Modern amb puntes més a prop",
    info_es: "Moderno con puntas mas cerca",
    info_ca: "Modern amb puntes més a prop",
  },
  {
    _id: 4,
    nome_it: "Button down classico",
    nome_en: "Button down",
    nome_fr: "Cou amèricain button down",
    nome_es: "Button down",
    nome_ca: "Buttom down clàssic",
    info_it: "Casual ideale senza la cravatta",
    info_en: "Casual ideal without tie",
    info_fr: "Casual ideal per portar sensa corbata",
    info_es: "Casual ideal para ir sin corbata",
    info_ca: "Casual ideal per portar sensa corbata",
  },
  {
    _id: 5,
    nome_it: "Diplomatico",
    nome_en: "Diplomatic",
    nome_fr: "Cou amèricain button down haute deux boutons",
    nome_es: "Diplomático",
    nome_ca: "Diplomatic",
    immagine: "./img/diplomatico.png",
    info_it: "Elegante per Smoking",
    info_en: "Elegant for Smoking",
    info_fr: "Elegant per Smoking",
    info_es: "Elegante para Smoking",
    info_ca: "Elegant per Smoking",
  },
  {
    _id: 6,
    nome_it: "Coreano",
    nome_en: "Corean",
    nome_fr: "Cou cassè",
    nome_es: "Mao",
    nome_ca: "Mao",
    info_it: "Casual e trendy",
    info_en: "Casual and trendy",
    info_fr: "Casual i trendy",
    info_es: "Casual y trendy",
    info_ca: "Casual i trendy",
  },
];

export const navPolsini = [
  {
    _id: 0,
    nome_it: "Arrotondato",
    nome_en: "Rounded",
    nome_fr: "Poignet ronde",
    nome_es: "Redondeado",
    nome_ca: "Rodó",
    info_it: "Casual adatto ad ogni situazione",
    info_en: "Casual suitable for any occasion",
    info_fr: "Casual i perfecte per a qualsevol ocasió",
    info_es: "Casual y perfecto para cualquier situación",
    info_ca: "Casual i perfecte per a qualsevol ocasió",
  },
  {
    _id: 1,
    nome_it: "Smussato",
    nome_en: "Beveled",
    nome_fr: "Poignet carre",
    nome_es: "Biselado",
    nome_ca: "Biselat",
    info_it: "Classico ed elegante",
    info_en: "Classic and elegant",
    info_fr: "Clasico i elegante",
    info_es: "Clasico y elegante",
    info_ca: "Clasico i elegante",
  },
  {
    _id: 2,
    nome_it: "Dritto",
    nome_en: "Straight",
    nome_fr: "Manchette droite",
    nome_es: "Recto",
    nome_ca: "Recte",
    info_it: "Classico ed informale",
    info_en: "Classic and informal",
    info_fr: "Clasico i informal",
    info_es: "Clasico y informal",
    info_ca: "Clasico i informal",
  },
  {
    _id: 3,
    nome_it: "Gemelli",
    nome_en: "Cufflinks",
    nome_fr: "Poignet manchette",
    nome_es: "Gemelos",
    nome_ca: "Bessons",
    info_it: "Elegante e raffinato",
    info_en: "Elegant and refined",
    info_fr: "Elegante i refinat",
    info_es: "Elegante y refinado",
    info_ca: "Elegante i refinat",
  },
  {
    _id: 4,
    nome_it: "Manica corta",
    nome_en: "Short sleeve",
    nome_fr: "Manche courte",
    nome_es: "Manga corta",
    nome_ca: "Màniga curta",
    info_it: "",
    info_en: "",
    info_fr: "",
    info_es: "",
    info_ca: "",
  },
];

export const navChiusure = [
  {
    _id: 0,
    nome_it: "Francese",
    nome_en: "French",
    nome_fr: "Fermeture simple",
    nome_es: "Francés",
    nome_ca: "Francès",
    info_it: "Versatile ed elegante",
    info_en: "Versatile and elegant",
    info_fr: "Versatil i elegant",
    info_es: "Versatil y elegante",
    info_ca: "Versatil i elegant",
  },
  {
    _id: 1,
    nome_it: "Classica",
    nome_en: "Standard",
    nome_fr: "Fermeture",
    nome_es: "Estándar",
    nome_ca: "Clàssic",
    info_it: "Classica ed informale",
    info_en: "Classic and informal",
    info_fr: "Classic i formal",
    info_es: "Clasico e informal",
    info_ca: "Classic i formal",
  },
  {
    _id: 2,
    nome_it: "Nascosta",
    nome_en: "Hidden",
    nome_fr: "Fermeture cachè",
    nome_es: "Oculto",
    nome_ca: "Amagat",
    info_it: "Adatta a camicie di gala",
    info_en: "Suitable for any occasion",
    info_fr: "Ideal per camises de gala",
    info_es: "Ideal para camisas de gala",
    info_ca: "Ideal per camises de gala",
  },
];

export const navTaschini = [
  {
    _id: 0,
    nome_it: "Nessun taschino",
    nome_en: "No pocket",
    nome_fr: "Sans poches",
    nome_es: "No bolsillo",
    nome_ca: "Cap butxaca",
  },
  {
    _id: 1,
    nome_it: "Arrotondato",
    nome_en: "Rounded",
    nome_fr: "Arrondie",
    nome_es: "Redondeado",
    nome_ca: "Rodona",
  },
  {
    _id: 2,
    nome_it: "Smussato",
    nome_en: "Beveled",
    nome_fr: "Poche carre",
    nome_es: "Biselado",
    nome_ca: "Biselada",
  },
  {
    _id: 3,
    nome_it: "Due taschini arrotondati",
    nome_en: "Two rounded pockets",
    nome_fr: "Deux poches arrondie",
    nome_es: "Dos bolsillos redondeados",
    nome_ca: "Dues butxaques rodones",
  },
  {
    _id: 4,
    nome_it: "Due taschini smussati",
    nome_en: "Two beveled pockets",
    nome_fr: "Deux poches carre",
    nome_es: "Dos bolsillos biselados",
    nome_ca: "Dues butxaques biselades",
  },
];

export const navPinces = [
  {
    _id: 0,
    nome_it: "Dietro liscio",
    nome_en: "No pinces",
    nome_fr: "Sans pinces",
    nome_es: "Detrás liso",
    nome_ca: "Darrera llis",
    info_it: "Senza pince",
    info_en: "Without pinces",
    info_fr: "Sensa pinces",
    info_es: "Sin pinzas",
    info_ca: "Sensa pinces",
  },
  {
    _id: 1,
    nome_it: "Dietro con pince centrale",
    nome_en: "One pince",
    nome_fr: "Pinces au centre",
    nome_es: "Detrás con pinza central",
    nome_ca: "Darrera amb pinça central",
    info_it: "Pince centrale",
    info_en: "Pince in the middle",
    info_fr: "Pinça al centre",
    info_es: "Pinza en el centro",
    info_ca: "Pinça al centre",
  },
  {
    _id: 2,
    nome_it: "Dietro con doppie pinces",
    nome_en: "Double pinces",
    nome_fr: "Double pinces",
    nome_es: "Detrás con pinzas dobles",
    nome_ca: "Darrera amb pinces laterals",
    info_it: "Pince ai lati",
    info_en: "Lateral Pinces",
    info_fr: "Pinces als costats",
    info_es: "Pinzas en los laterales",
    info_ca: "Pinces als costats",
  },
  {
    _id: 3,
    nome_it: "Dietro con riprese",
    nome_en: "With shots",
    nome_fr: "Derrière avec des coups",
    nome_es: "Detrás con tiros",
    nome_ca: "Darrere amb trets",
    info_it: "",
    info_en: "",
    info_fr: "",
    info_es: "",
    info_ca: "",
  },
];

export const navBottoni = [
  {
    _id: 0,
    nome_it: "Bianco",
    nome_en: "White",
    nome_fr: "Blanc",
    nome_es: "Blancos",
    nome_ca: "Blanc",
  },
  {
    _id: 1,
    nome_it: "Blu",
    nome_en: "Blue",
    nome_fr: "Bleu",
    nome_es: "Azules",
    nome_ca: "Blau marí",
  },
  {
    _id: 2,
    nome_it: "Celeste",
    nome_en: "Light blue",
    nome_fr: "Bleu clair",
    nome_es: "Celestes",
    nome_ca: "Blau cel",
  },
  {
    _id: 3,
    nome_it: "Grigio",
    nome_en: "Grey",
    nome_fr: "Gris",
    nome_es: "Grises",
    nome_ca: "Grigio",
  },
  {
    _id: 4,
    nome_it: "Nero",
    nome_en: "Black",
    nome_fr: "Noir",
    nome_es: "Negros",
    nome_ca: "Negre",
  },
  {
    _id: 5,
    nome_it: "Rosso",
    nome_en: "Red",
    nome_fr: "Rouge",
    nome_es: "Rojos",
    nome_ca: "Vermell",
  },
  {
    _id: 6,
    nome_it: "Arancione",
    nome_en: "Orange",
    nome_fr: "Boutons orange",
    nome_es: "Naranja",
    nome_ca: "Arancione",
  },
  {
    _id: 7,
    nome_it: "Verde",
    nome_en: "Green",
    nome_fr: "Boutons vert",
    nome_es: "Verde",
    nome_ca: "Verde",
  },
  {
    _id: 8,
    nome_it: "Rosa",
    nome_en: "Pink",
    nome_fr: "Boutons rose",
    nome_es: "Rosado",
    nome_ca: "Rosa",
  },
];

export const navAsole = [
  {
    _id: -1,
    nome_it: "Colore camicia",
    nome_en: "Fabric color",
    nome_es: "Fabric color",
    nome_fr: "Fabric color",
    nome_ca: "Fabric color",
  },
  {
    _id: 0,
    nome_it: "Asola Bianca",
    nome_en: "White buttonholes",
    nome_es: "Ojal blanco",
    nome_fr: "Boutonnière blanc",
    nome_ca: "Trau blanc",
  },
  {
    _id: 1,
    nome_it: "Blu",
    nome_en: "Blue",
    nome_fr: "Bleu",
    nome_es: "Azul",
    nome_ca: "Blau marí",
  },
  {
    _id: 2,
    nome_it: "Celeste",
    nome_en: "Light blue",
    nome_fr: "Bleu clair",
    nome_es: "Celeste",
    nome_ca: "Blau cel",
  },
  {
    _id: 3,
    nome_it: "Grigia",
    nome_en: "Grey",
    nome_fr: "Gris",
    nome_es: "Gris",
    nome_ca: "Grigia",
  },
  {
    _id: 4,
    nome_it: "Nera",
    nome_en: "Black",
    nome_fr: "Noir",
    nome_es: "Negro",
    nome_ca: "Negre",
  },
  {
    _id: 5,
    nome_it: "Rossa",
    nome_en: "Red",
    nome_fr: "Boutonnière rouge",
    nome_es: "Rojo",
    nome_ca: "Vermell",
  },
  {
    _id: 6,
    nome_it: "Arancione",
    nome_en: "Orange",
    nome_fr: "Boutonnière orange",
    nome_es: "Naranja",
    nome_ca: "Arancione",
  },
  {
    _id: 7,
    nome_it: "Verde",
    nome_en: "Green",
    nome_fr: "Vert",
    nome_es: "Verde",
    nome_ca: "Verde",
  },
  {
    _id: 8,
    nome_it: "Rosa",
    nome_en: "Pink",
    nome_fr: "Rose",
    nome_es: "Rosada",
    nome_ca: "Rosa",
  },
];

export const navFili = [
  {
    _id: 0,
    nome_it: "Bianco",
    nome_en: "White",
    nome_fr: "Fil blanc",
    nome_es: "Blanco",
    nome_ca: "Blanc",
  },
  {
    _id: 1,
    nome_it: "Blu",
    nome_en: "Blue",
    nome_fr: "Fil bleu",
    nome_es: "Azul",
    nome_ca: "Blau marí",
  },
  {
    _id: 2,
    nome_it: "Celeste",
    nome_en: "Light blue",
    nome_fr: "Bleu clair",
    nome_es: "Celeste",
    nome_ca: "Blau cel",
  },
  {
    _id: 3,
    nome_it: "Rosa",
    nome_en: "Pink",
    nome_fr: "Fil gris",
    nome_es: "Rosas",
    nome_ca: "Rosa",
  },
  {
    _id: 4,
    nome_it: "Nero",
    nome_en: "Black",
    nome_fr: "Noir",
    nome_es: "Negro",
    nome_ca: "Negre",
  },
  {
    _id: 5,
    nome_it: "Rosso",
    nome_en: "Red",
    nome_fr: "Fil rouge",
    nome_es: "Rojo",
    nome_ca: "Vermell",
  },
];

export const navContrasti = [
  {
    _id: 0,
    nome_it: "Nessun contrasto",
    nome_en: "No contrast",
    nome_fr: "No contraste",
    nome_es: "No contraste",
    nome_ca: "Cap contrast",
  },
  {
    _id: 1,
    nome_it: "Hutch 2441S-W21220-0007",
    nome_en: "Hutch 2441S-W21220-0007",
    nome_fr: "Hutch 2441S-W21220-0007",
    nome_es: "Hutch 2441S-W21220-0007",
    nome_ca: "Hutch 2441S-W21220-0007",
  },
  {
    _id: 2,
    nome_it: "Rock 2317H-W22999-0001",
    nome_en: "Rock 2317H-W22999-0001",
    nome_fr: "Rock 2317H-W22999-0001",
    nome_es: "Rock 2317H-W22999-0001",
    nome_ca: "Rock 2317H-W22999-0001",
  },
  {
    _id: 3,
    nome_it: "Rock 2317H-W22993-0001",
    nome_en: "Rock 2317H-W22993-0001",
    nome_fr: "Rock 2317H-W22993-0001",
    nome_es: "Rock 2317H-W22993-0001",
    nome_ca: "Rock 2317H-W22993-0001",
  },
];
