import React from "react"
import { connect } from "react-redux"
import { setchiusura, setopenmain } from "../../../state/actions"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { navChiusure } from "../navigazione"

const Chiusure = props => {
  const data = useStaticQuery(graphql`
  query MyChiusure {
    allFile(sort: {fields: name}, filter: { absolutePath: { regex: "/chiusure/img/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 400, maxHeight: 250) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`)
  const { lingua, chiusa, newChiusura, setOpenMain } = props

  const setChiusure = tipo => {
    newChiusura(tipo)
    setOpenMain("")
  }

  return (
    <div className="section">
      <div
        className="row columns is-multiline"
      >
        {navChiusure.map(chiusura => (
          <div
            className="column is-half"
            key={chiusura._id}
            
          >
            <button className="buttonLink" onClick={() => setChiusure(chiusura._id)}>
            <div className="card">
                <div className="card-image">
                <Img
                    fluid={
                      data.allFile.edges[chiusura._id].node.childImageSharp
                        .fluid
                    }
                    alt={chiusura[`nome_${lingua.lang}`]}
                  />
                </div>
                <div
                  className={
                    chiusa === chiusura._id
                      ? "card-content has-background-grey-lighter"
                      : "card-content"
                  }
                >
                  <div className="media">
                    <div className="media-content">
                    <p
                        className="title is-7 is-uppercase">
                        {chiusura[`nome_${lingua.lang}`]}
                      </p>

                      <p
                        className="subtitle is-7">
                        {chiusura[`info_${lingua.lang}`]}
                        </p>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    chiusa: state.shop.camicia.chiusura,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    newChiusura: val => dispatch(setchiusura(val)),
    setOpenMain: val => dispatch(setopenmain(val)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Chiusure)
