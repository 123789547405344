import React, { useState } from "react";
import { connect } from "react-redux";
import { setopenmain } from "../../state/actions";

import { euro } from "../helpers";
import { Link } from "gatsby";

import Img from "gatsby-image";

const Card = (props) => {
  const {
    prezzo,
    prezzo_old,
    nome,
    codice,
    immagine,
    brand,
  } = props.tessuto.data;
  const { tessuto } = props;
  const { lingua, attivo, setOpenMain } = props;
  const slug = props.tessuto.data.Path;

  const [open, setOpen] = useState("");


  return (
    <>
      {/* <Link to={`/${slug}/`} onClick={() => setOpenMain("")}> */}
      <div
        className="card"
        style={attivo ? { cursor: "auto" } : { cursor: "pointer" }}
        onClick={() => {
          attivo ? setOpen("") : setOpen("is-active");
        }}
      >
        <div className="card-image" style={{ position: "relative" }}>
          <Img
            fluid={immagine.localFiles[0].childImageSharp.fluid}
            alt={nome}
          />

          {prezzo < prezzo_old && (
            <span
              style={{ position: "absolute", top: "0.75rem", right: 0 }}
              className="tag is-primary is-uppercase is-radiusless"
            >
              {lingua.inOfferta}
            </span>
          )}
        </div>
        <div
          className={
            attivo ? "card-content has-background-grey-lighter" : "card-content"
          }
          data-tooltip={tessuto.data[`colore_dettaglio_${lingua.lang}`]}
        >
          <div className="media">
            <div className="media-content">
              <p className="title is-7 is-uppercase">{nome}</p>

              <p className="subtitle is-7">{tessuto.data[`descrizione_breve_${lingua.lang}`]}</p>
              <p className="is-size-6 has-text-weight-semibold has-text-right">
                {prezzo < prezzo_old && (
                  <s
                    className="is-size-7"
                    style={{ marginRight: "0.75rem", opacity: "0.5" }}
                  >
                    {euro(prezzo_old)}
                  </s>
                )}
                {euro(prezzo)}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* </Link> */}

      <div className={"modal " + open}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div
            className="section"
            style={{ background: "#fff", padding: "1.5rem" }}
          >
            <div className="columns">
              <div className="column">
                <Img
                  fluid={immagine.localFiles[0].childImageSharp.fluid}
                  alt={nome}
                />
              </div>
              <div className="column">
                <p className="title is-4 is-uppercase">{nome}</p>

                <p className="subtitle is-5">{codice}</p>
                <p style={{ marginBottom: "1.5rem" }} className="is-uppercase">
                  {brand}
                </p>
                <p style={{ marginBottom: "1.5rem" }}>
                  {tessuto.data[`descrizione_breve_${lingua.lang}`]}
                </p>
                <p
                  className="is-size-5 has-text-weight-semibold"
                  style={{ marginBottom: "1.5rem" }}
                >
                  {prezzo < prezzo_old && (
                    <s
                      className="is-size-7"
                      style={{ marginRight: "0.75rem", opacity: "0.5" }}
                    >
                      {euro(prezzo_old)}
                    </s>
                  )}
                  {euro(prezzo)}
                </p>

                <div className="buttons">
                  <Link
                    className="button is-dark is-radiusless"
                    to={`/${slug}/`}
                    onClick={() => setOpenMain("")}
                  >
                    {lingua.scegliTessuto}
                  </Link>

                  <Link
                    className="button is-radiusless"
                    to={`/fabrics-family/`}
                    onClick={() => setOpenMain("")}
                  >
                    {lingua.footer.famiglie}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => {
            setOpen("");
          }}
        />
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setOpenMain: (val) => dispatch(setopenmain(val)),
  };
}

export default connect(null, mapDispatchToProps)(Card);
