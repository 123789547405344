import React from "react";
import { connect } from "react-redux";
import { setfilo, setopenmain } from "../../../state/actions";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { navFili } from "../navigazione";

const Fili = (props) => {
  const data = useStaticQuery(graphql`
    query MyFili {
      allFile(
        sort: { fields: name }
        filter: { absolutePath: { regex: "/fili/img/" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 250) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  const { lingua, fil, newFilo, setOpenMain } = props;

  const setFilo = (tipo) => {
    newFilo(tipo);
    setOpenMain("");
  };

  return (
    <div className="section">
      <div className="row columns is-multiline">
        {navFili.map((filo) => (
          <div className="column is-half" key={filo._id}>
            <button className="buttonLink" onClick={() => setFilo(filo._id)}>
              <div className={fil === filo._id ? "card is-active" : "card"}>
                <div className="card-image">
                  <Img
                    fluid={
                      data.allFile.edges[filo._id].node.childImageSharp.fluid
                    }
                    alt={filo[`nome_${lingua.lang}`]}
                  />
                </div>
                <div
                  className={
                    fil === filo._id
                      ? "card-content has-background-grey-lighter"
                      : "card-content"
                  }
                >
                  <div className="media">
                    <div className="media-content">
                      <p className="title is-7 is-uppercase">
                        {filo[`nome_${lingua.lang}`]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    fil: state.shop.camicia.filo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newFilo: (val) => dispatch(setfilo(val)),
    setOpenMain: (val) => dispatch(setopenmain(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Fili);
